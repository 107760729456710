@use "./../abstract/colors";


.back-to-top {
  background-color: colors.$main-theme-color-dark;

  border: {
    color: colors.$main-white-transparent;
    radius: 50%;
    style: solid;
    width: 1px;
  }

  box-shadow: 0 0 3px 1px colors.$main-white-color;

  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;


  height: 50px;
  width: 50px;

  opacity: 1;

  position: fixed;

  right: 50px;
  bottom: 50px;

  transition: all 400ms;
  user-select: none;

  z-index: 99;

  @media only screen and (min-width: 1200px) {
    height: 75px;
    width: 75px;
  }

  @media only screen and (max-width: 768px) {
    right: 5vw;
    bottom: 5vw;
  }
}

.back-to-top:hover {
  background-color: colors.$main-theme-color-light;
}

.back-to-top > img {
  height: 20px;
  width: 20px;

  transition: all 400ms;

  @media only screen and (min-width: 1200px) {
    height: 30px;
    width: 30px;
  }
}

.button-unvisible {
  opacity: 0;
}
