@use '../abstract/colors';

// FONTS

// font-family
$main-sans-serif: Lato, Helvetica, sans-serif;
$main-monospace: 'Roboto Mono', Inconsolata, monospace;


// BORDERS

$default-border: 4px solid colors.$main-white-transparent;


// BOX SHADOW

$box-shadow-pink: 0.66rem 0.66rem colors.$main-pink-color;
$box-shadow-yellow: 0.66rem 0.66rem colors.$main-yellow-color;
$box-shadow-blue: 0.66rem 0.66rem colors.$main-blue-color;

// Small devices (mobile)
$box-shadow-pink--sm: 0.5rem 0.5rem colors.$main-pink-color;
$box-shadow-yellow--sm: 0.5rem 0.5rem colors.$main-yellow-color;
$box-shadow-blue--sm: 0.5rem 0.5rem colors.$main-blue-color;
