@use '../abstract/colors';
@use '../abstract/variables';


// BACKGROUND

.about .main-header__wrapper,
.about .main-nav-small__links {
  background-image: linear-gradient(to bottom, colors.$main-theme-color-light-transparent, colors.$main-theme-color-dark-transparent), url('../../img/backgrounds/about_bg.jpg');
}


// MY NAME IS

.about-me__my-name-is {
  padding: 2.66rem 0;
}

.about-me__photo-and-array {
  display: flex;
  
  justify-content: flex-start;

  @media only screen and (max-width: 480px) {
    flex-wrap: wrap;
  }
}

.about-me__photo--wrapper {
  width: auto;
  height: auto;
  margin-right: 2.66rem;

  @media only screen and (max-width: 480px) {
    margin-right: auto;
    margin-bottom: 2.66rem;
  }
}

.about-me__photo {
  border: variables.$default-border;

  box-shadow: 0.66rem 0.66rem colors.$main-pink-color,
              1.32rem 1.32rem colors.$main-yellow-color,
              1.98rem 1.98rem colors.$main-blue-color;
  
  margin-right: 2rem;
  margin-bottom: 2rem;

  @media only screen and (max-width: 768px) {
    box-shadow: 0.5rem 0.5rem colors.$main-pink-color,
              1rem 1rem colors.$main-yellow-color,
              1.5rem 1.5rem colors.$main-blue-color;
    
    margin-right: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

.about-me__photo > img {
  width: clamp(300px, 90vw, 500px);
}

.about-me__skills-array {
  max-width: 40vw;
}

.about-me__skills-array > p {
  margin-top: auto;
  font-size: clamp(12px, 2vw, 20px);

  @media only screen and (max-width: 480px) {
    font-size: clamp(16px, 2vw, 24px);
  }
}

.about-me__description {
  margin-top: 2.66rem;
  border: variables.$default-border;

  margin-bottom: 1.33rem;
  padding: 2rem 5.33rem 2rem 4rem;

  transition: background-color 400ms;

  @media only screen and (max-width: 768px) {
    padding: 2rem 3rem;
  }

  @media only screen and (max-width: 480px) {
    padding: 3rem 2rem;
  }

  text-align: justify;
}

.about-me__description span {
  font-weight: 700;
}

.about-me__description span:nth-of-type(3n) {
  color: colors.$blue-text;
}

.about-me__description span:nth-of-type(3n + 1) {
  color: colors.$pink-text;
}

.about-me__description span:nth-of-type(3n - 1) {
  color: colors.$yellow-text;
}


// TECHNOLOGIES

.about-me__technologies {
  background-color: colors.$main-theme-color-light;
  padding: 2.66rem 0;
}

.technologies {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 1fr;
  gap: 1.33rem;

  margin-bottom: 1.33rem;

  @media only screen and (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.technology {
  aspect-ratio: 1;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  user-select: none;
}

.technology:nth-of-type(3n) {
  border: 4px solid colors.$main-blue-transparent;
}

.technology:nth-of-type(3n + 1) {
  border: 4px solid colors.$main-pink-transparent;
}

.technology:nth-of-type(3n - 1) {
  border: 4px solid colors.$main-yellow-transparent;
}

.technology__logo {
  padding: 2rem;

  height: 100%;
  width: 100%;

  @media only screen and (max-width: 768px) {
    padding: 1rem;
  }

  @media only screen and (max-width: 480px) {
    padding: 2rem;
  }
}

.technology__logo > img {
  height: 100%;
  width: 100%;
}

.technology__name {
  align-items: center;
  display: flex;
  justify-content: center;
  
  background-color: colors.$main-theme-color-dark;
  
  padding: 2rem;
  
  position: absolute;
  top: 100%;
  z-index: 99;
  
  width: 100%;
  height: 100%;

  text-align: center;

  transition: top 400ms;

  @media only screen and (max-width: 768px) {
    padding: 1rem;
  }

  @media only screen and (max-width: 480px) {
    padding: 2rem;
  }
}

.technology__name {
  font-size: 1.33rem;

  @media only screen and (max-width: 768px) {
    font-size: 1rem;
  }

  @media only screen and (max-width: 480px) {
    font-size: 1.33rem;
  }
}

.technology:nth-of-type(3n) > .technology__name > p {
  color: colors.$blue-text;
}

.technology:nth-of-type(3n + 1) > .technology__name > p {
  color: colors.$pink-text;
}

.technology:nth-of-type(3n - 1) > .technology__name > p { 
  color: colors.$yellow-text;
}

.technology:hover .technology__name {
  top: 0;
  transition: top 300ms;
}
