@use '../abstract/colors';


// FADE IN

@-webkit-keyframes fade-in {
  from  { opacity: 0 }
  to    { opacity: 1 }
}

@keyframes fade-in {
  from  { opacity: 0 }
  to    { opacity: 1 }
}


// FADE OUT BACKGROUND

@-webkit-keyframes fade-out-main-bg {
  from  { background-color: colors.$main-theme-color-dark; }
  to    { background-color: hsla(0, 0%, 0%, 0) }
}

@keyframes fade-out-main-bg {
  from  { background-color: colors.$main-theme-color-dark; }
  to    { background-color: hsl(0, 0%, 0%, 0) }
}


// FADE IN FROM LEFT

@-webkit-keyframes fade-in-from-left {
  from  { 
    opacity: 0;
    -webkit-transform: translateX(-10%);
        -ms-transform: translateX(-10%);
            transform: translateX(-10%); 
  }

  to    { 
    opacity: 1;
    -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes fade-in-from-left {
  from  { 
    opacity: 0;
    -webkit-transform: translateX(-10%);
        -ms-transform: translateX(-10%);
            transform: translateX(-10%); 
  }

  to    { 
    opacity: 1;
    -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
            transform: translateX(0); 
  }
}
