// JUMPING BUTTON ANIMATION.

@keyframes moving-button {
  0%    {
    -webkit-transform: translate(-50%, -1.58rem);
        -ms-transform: translate(-50%, -1.58rem);
            transform: translate(-50%, -1.58rem); 
  }
  94%   {
    -webkit-transform: translate(-50%, -1.58rem);
        -ms-transform: translate(-50%, -1.58rem);
            transform: translate(-50%, -1.58rem); 
  }
  97%   {
    -webkit-transform: translate(-50%, -0.5rem);
        -ms-transform: translate(-50%, -0.5rem);
            transform: translate(-50%, -0.5rem); 
  }
  100%  {
    -webkit-transform: translate(-50%, -1.58rem);
        -ms-transform: translate(-50%, -1.58rem);
            transform: translate(-50%, -1.58rem); 
  } 
}
