/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin and padding */
* {
  margin: 0;
  padding: 0;
}

/* Remove list styles on ul, ol elements, and on ul, ol with a list role, which suggests default styling will be removed */
ul[role=list],
ol[role=list],
ul,
ol {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* A elemets do not have blue (default) color */
a {
  color: inherit;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
*::before,
*::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
@font-face {
  font-family: "Lato";
  src: local("Lato") local("Lato Regular") url("../../fonts/Lato-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: local("Lato") local("Lato Bold") url("../../fonts/Lato-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: local("Lato") local("Lato Black") url("../../fonts/Lato-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Roboto Mono";
  src: local("Roboto Mono") local("Roboto Mono Regular") url("../../fonts/RobotoMono-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Roboto Mono";
  src: local("Roboto Mono") local("Roboto Mono Bold") url("../../fonts/RobotoMono-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Inconsolata";
  src: local("Inconsolata") local("Inconsolata Regular") url("../../fonts/Inconsolata-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Inconsolata";
  src: local("Inconsolata") local("Inconsolata Bold") url("../../fonts/Inconsolata-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
* {
  font-family: Lato, Helvetica, sans-serif;
  color: hsl(0deg, 0%, 100%);
}

html {
  font-size: clamp(16px, 2vw, 24px);
}

h1 {
  font-size: 4rem;
  line-height: normal;
  text-transform: uppercase;
}
@media only screen and (max-width: 480px) {
  h1 {
    font-size: 3rem;
  }
}

h2 {
  font-size: 2rem;
  margin-bottom: 2.66rem;
  text-transform: uppercase;
}

h3 {
  font-size: 1.33rem;
}

small {
  font-size: 0.66rem;
}

.code-string > * {
  font-family: "Roboto Mono", Inconsolata, monospace;
  font-weight: 700;
}

.mono-string {
  font-family: "Roboto Mono", Inconsolata, monospace;
}

.blue-text {
  color: hsl(201deg, 100%, 75%);
}

.pink-text {
  color: hsl(339deg, 100%, 75%);
}

.yellow-text {
  color: hsl(68deg, 100%, 75%);
}

.white-text {
  color: hsl(0deg, 0%, 100%);
}

.purple-text {
  color: hsl(273deg, 100%, 75%);
}

.orange-text {
  color: hsl(21deg, 100%, 75%);
}

.green-text {
  color: hsl(130deg, 100%, 75%);
}

@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-out-main-bg {
  from {
    background-color: hsl(213deg, 100%, 12%);
  }
  to {
    background-color: hsla(0deg, 0%, 0%, 0);
  }
}
@keyframes fade-out-main-bg {
  from {
    background-color: hsl(213deg, 100%, 12%);
  }
  to {
    background-color: hsla(0deg, 0%, 0%, 0);
  }
}
@-webkit-keyframes fade-in-from-left {
  from {
    opacity: 0;
    -webkit-transform: translateX(-10%);
    -ms-transform: translateX(-10%);
    transform: translateX(-10%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes fade-in-from-left {
  from {
    opacity: 0;
    -webkit-transform: translateX(-10%);
    -ms-transform: translateX(-10%);
    transform: translateX(-10%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes moving-button {
  0% {
    -webkit-transform: translate(-50%, -1.58rem);
    -ms-transform: translate(-50%, -1.58rem);
    transform: translate(-50%, -1.58rem);
  }
  94% {
    -webkit-transform: translate(-50%, -1.58rem);
    -ms-transform: translate(-50%, -1.58rem);
    transform: translate(-50%, -1.58rem);
  }
  97% {
    -webkit-transform: translate(-50%, -0.5rem);
    -ms-transform: translate(-50%, -0.5rem);
    transform: translate(-50%, -0.5rem);
  }
  100% {
    -webkit-transform: translate(-50%, -1.58rem);
    -ms-transform: translate(-50%, -1.58rem);
    transform: translate(-50%, -1.58rem);
  }
}
.back-to-top {
  background-color: hsl(213deg, 100%, 12%);
  border-color: hsla(0deg, 0%, 100%, 0.5);
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 0 3px 1px hsl(0deg, 0%, 100%);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  opacity: 1;
  position: fixed;
  right: 50px;
  bottom: 50px;
  transition: all 400ms;
  user-select: none;
  z-index: 99;
}
@media only screen and (min-width: 1200px) {
  .back-to-top {
    height: 75px;
    width: 75px;
  }
}
@media only screen and (max-width: 768px) {
  .back-to-top {
    right: 5vw;
    bottom: 5vw;
  }
}

.back-to-top:hover {
  background-color: hsl(202deg, 60%, 27%);
}

.back-to-top > img {
  height: 20px;
  width: 20px;
  transition: all 400ms;
}
@media only screen and (min-width: 1200px) {
  .back-to-top > img {
    height: 30px;
    width: 30px;
  }
}

.button-unvisible {
  opacity: 0;
}

.app {
  overflow-x: hidden;
  width: 100%;
}

.main-header__wrapper {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 100vh;
  width: 100%;
}

.main-header {
  -o-animation: fade-out-main-bg 600ms 150ms 1 normal forwards;
  -moz-animation: fade-out-main-bg 600ms 150ms 1 normal forwards;
  -webkit-animation: fade-out-main-bg 600ms 150ms 1 normal forwards;
  animation: fade-out-main-bg 600ms 150ms 1 normal forwards;
  background-color: hsl(213deg, 100%, 12%);
}

.main-header__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.main-header__middle__heading {
  border: 0.42rem solid hsl(0deg, 0%, 100%);
  box-shadow: inset 0 0 0 1000px hsla(202deg, 60%, 27%, 0.9);
  overflow: hidden;
  padding: 1.75rem 2rem;
}

.main-header__middle__heading > h1 {
  -o-animation: fade-in-from-left 300ms 150ms 1 normal forwards;
  -moz-animation: fade-in-from-left 300ms 150ms 1 normal forwards;
  -webkit-animation: fade-in-from-left 300ms 150ms 1 normal forwards;
  animation: fade-in-from-left 300ms 150ms 1 normal forwards;
  opacity: 0;
}

.code-string {
  -o-animation: fade-in 300ms 150ms 1 normal forwards;
  -moz-animation: fade-in 300ms 150ms 1 normal forwards;
  -webkit-animation: fade-in 300ms 150ms 1 normal forwards;
  animation: fade-in 300ms 150ms 1 normal forwards;
  margin-top: 0.66rem;
  opacity: 0;
}

.main-header__bottom {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  padding: 1.33rem 0;
  position: relative;
}

.go-down-button {
  -o-animation: moving-button 10000ms 0s infinite normal forwards;
  -moz-animation: moving-button 10000ms 0s infinite normal forwards;
  -webkit-animation: moving-button 10000ms 0s infinite normal forwards;
  animation: moving-button 10000ms 0s infinite normal forwards;
  background-color: hsl(202deg, 60%, 27%);
  border: 0.21rem solid hsl(0deg, 0%, 100%);
  cursor: pointer;
  padding: 1rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -1.58rem);
  transition: background-color 300ms;
  user-select: none;
}

.go-down-button:hover {
  background-color: transparent;
  transition: background-color 150ms;
}

.go-down-button > img {
  width: 1.33rem;
}

.main-nav {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  padding: 1.33rem 0;
}

.main-nav a,
.main-nav-small__bar a {
  text-decoration: none;
}

.main-nav__list {
  display: flex;
}

.main-nav__list li {
  margin-left: 1.33rem;
}

.main-nav__list a {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main-nav__list p {
  text-transform: uppercase;
}

.active-link--home > div,
.active-link--projects > div,
.active-link--about > div,
.in-active-link--home > div,
.in-active-link--projects > div,
.in-active-link--about > div {
  height: 1.33rem;
  width: 1.33rem;
  background-repeat: no-repeat;
  background-size: contain;
}

.active-link--home > div {
  background-image: url("../../img/icons/navigation/home_icon_active.svg");
}

.active-link--projects > div {
  background-image: url("../../img/icons/navigation/projects_icon_active.svg");
}

.active-link--about > div {
  background-image: url("../../img/icons/navigation/about_icon_active.svg");
}

.in-active-link--home > div {
  background-image: url("../../img/icons/navigation/home_icon.svg");
}

.in-active-link--projects > div {
  background-image: url("../../img/icons/navigation/projects_icon.svg");
}

.in-active-link--about > div {
  background-image: url("../../img/icons/navigation/about_icon.svg");
}

.active-link--home > p {
  color: hsl(201deg, 100%, 75%);
}

.active-link--projects > p {
  color: hsl(339deg, 100%, 75%);
}

.active-link--about > p {
  color: hsl(68deg, 100%, 75%);
}

.in-active-link--home > p,
.in-active-link--projects > p,
.in-active-link--about > p {
  color: hsl(0deg, 0%, 100%);
  transition: color 150ms;
}

.in-active-link--home:hover > p {
  color: hsl(201deg, 100%, 75%);
}

.in-active-link--projects:hover > p {
  color: hsl(339deg, 100%, 75%);
}

.in-active-link--about:hover > p {
  color: hsl(68deg, 100%, 75%);
}

.main-nav-small__bar {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 1.33rem 0;
  transition: all 400ms;
}

.main-nav-small__bar > a {
  font-size: clamp(18px, 4vw, 24px);
}

.main-nav-small__bar__button {
  background-color: transparent;
  border-color: hsla(0deg, 0%, 100%, 0.5);
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 0 3px 1px hsl(0deg, 0%, 100%);
  margin-right: 1.33rem;
  overflow: hidden;
  height: 50px;
  width: 50px;
}

.main-nav-small__bar__button:hover {
  background-color: hsl(202deg, 60%, 27%);
}

.main-nav-small__bar__button__icons-wrapper {
  align-items: center;
  display: flex;
  transition: translate 400ms;
}

.main-nav-small__bar__button__icons-wrapper > img {
  height: 20px;
  width: 20px;
  margin: 15px;
}

.navigation-opened {
  translate: -50px;
}

.navigation-opened--bar-bg {
  background-color: hsl(202deg, 60%, 27%);
  box-shadow: 0 -25px 0 25px hsla(202deg, 60%, 27%, 0.9);
}

.main-nav-small__links {
  background-color: hsl(213deg, 100%, 12%);
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  padding: 4rem 1.33rem;
  width: 100%;
  translate: 100%;
  transition: translate 400ms;
  z-index: 99;
}

.main-nav-small__links__list {
  display: flex;
  flex-direction: column;
}

.main-nav-small__links__list > li {
  margin-bottom: 2rem;
}

.main-nav-small__links__list > li > a {
  align-items: center;
  display: flex;
  font-size: clamp(24px, 6vw, 32px);
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
}

.main-nav-small__links__list > li > a > div {
  height: 42px;
  width: 42px;
  margin-right: 2rem;
}

.navigation-opened--links {
  translate: 0;
}

.main-content__wrapper {
  background-color: hsl(213deg, 100%, 12%);
  margin: 0 auto;
  width: 100%;
}

.footer {
  padding: 2.66rem 0;
}

.footer__links {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer__links > a {
  margin-bottom: 1.33rem;
  margin-right: 1.33rem;
  text-decoration: none;
}

.footer__links__external-links {
  display: flex;
}

.footer__links__external-links > div {
  align-items: center;
  display: flex;
  margin-bottom: 1.33rem;
  margin-right: 1.33rem;
}

.footer__links__external-links > div > img {
  height: 2rem;
  margin-right: 0.66rem;
}

.default-container-width {
  margin: 0 auto;
  width: clamp(300px, 90vw, 1150px);
}

.bg-wrapper--dark {
  background-color: hsl(213deg, 100%, 12%);
  width: 100%;
}

.bg-wrapper--light {
  background-color: hsl(202deg, 60%, 27%);
  width: 100%;
}

.bg-wrapper--gradient {
  background-image: linear-gradient(to bottom, hsla(213deg, 100%, 12%, 0.9), hsla(202deg, 60%, 27%, 0.9));
  width: 100%;
}

.home .main-header__wrapper,
.home .main-nav-small__links {
  background-image: linear-gradient(to bottom, hsla(202deg, 60%, 27%, 0.9), hsla(213deg, 100%, 12%, 0.9)), url("../../img/backgrounds/home_bg.jpg");
}

.home__my-experience {
  padding: 2.66rem 0;
}

.my-experience__content {
  border: 4px solid hsla(0deg, 0%, 100%, 0.5);
  margin-bottom: 1.33rem;
  padding: 2rem 5.33rem 2rem 4rem;
  transition: background-color 400ms;
}
@media only screen and (max-width: 768px) {
  .my-experience__content {
    padding: 2rem 3rem;
  }
}
@media only screen and (max-width: 480px) {
  .my-experience__content {
    padding: 3rem 2rem;
  }
}

.my-experience__content:hover {
  background-color: hsl(202deg, 60%, 27%);
  transition: background-color 150ms;
}

.my-experience__top {
  display: grid;
  grid-template-columns: 3rem auto;
  grid-template-rows: 3rem auto;
  gap: 0.66rem;
  margin-bottom: 2rem;
}

.my-experience__top > img {
  width: 100%;
}

.my-experience__top > h3 {
  align-self: center;
}

.my-experience__top__underline--pink {
  text-decoration: underline;
  text-decoration-color: hsl(339deg, 100%, 75%);
  text-decoration-thickness: 6px;
}

.my-experience__top__underline--yellow {
  text-decoration: underline;
  text-decoration-color: hsl(68deg, 100%, 75%);
  text-decoration-thickness: 6px;
}

.my-experience__top__underline--blue {
  text-decoration: underline;
  text-decoration-color: hsl(201deg, 100%, 75%);
  text-decoration-thickness: 6px;
}

.my-experience__bottom__tag {
  color: hsla(0deg, 0%, 100%, 0.5);
}

.my-experience__bottom__description {
  border-left: 1px solid hsla(0deg, 0%, 100%, 0.5);
  margin: 0.33rem 0 0.33rem 0.75rem;
  padding: 0.66rem 0 0.66rem 1rem;
}

.home__highlight-projects {
  background-color: hsl(202deg, 60%, 27%);
  padding: 2.66rem 0;
}

.highlight-projects__content {
  border: 4px solid hsla(0deg, 0%, 100%, 0.5);
  box-shadow: none;
  margin-bottom: 2.66rem;
  padding: 2rem 4rem;
  transition: all 400ms;
}
@media only screen and (max-width: 768px) {
  .highlight-projects__content {
    padding: 2rem 3rem;
  }
}
@media only screen and (max-width: 480px) {
  .highlight-projects__content {
    padding: 3rem 2rem;
  }
}

.highlight-projects__box-shadow:nth-of-type(3n) {
  box-shadow: 0.66rem 0.66rem hsl(201deg, 100%, 75%);
  transition: all 400ms;
}
@media only screen and (max-width: 768px) {
  .highlight-projects__box-shadow:nth-of-type(3n) {
    box-shadow: 0.5rem 0.5rem hsl(201deg, 100%, 75%);
  }
}

.highlight-projects__box-shadow:nth-of-type(3n + 1) {
  box-shadow: 0.66rem 0.66rem hsl(339deg, 100%, 75%);
  transition: all 400ms;
}
@media only screen and (max-width: 768px) {
  .highlight-projects__box-shadow:nth-of-type(3n + 1) {
    box-shadow: 0.5rem 0.5rem hsl(339deg, 100%, 75%);
  }
}

.highlight-projects__box-shadow:nth-of-type(3n - 1) {
  box-shadow: 0.66rem 0.66rem hsl(68deg, 100%, 75%);
  transition: all 400ms;
}
@media only screen and (max-width: 768px) {
  .highlight-projects__box-shadow:nth-of-type(3n - 1) {
    box-shadow: 0.5rem 0.5rem hsl(68deg, 100%, 75%);
  }
}

.highlight-projects__content:last-of-type {
  margin-bottom: 2rem;
}

.highlight-projects__content:hover {
  background-color: hsl(213deg, 100%, 12%);
  transition: all 150ms;
}

.highlight-projects__content > h3 {
  margin-bottom: 1.33rem;
}

.highlight-projects__content > h3 > a {
  font-size: 1.33rem;
}

.highlight-projects__content > p {
  margin-bottom: 1rem;
}

.highlight-projects__screen-shot {
  border: 4px solid hsla(0deg, 0%, 100%, 0.5);
  border-radius: 0.21rem;
  height: clamp(200px, 40vh, 450px);
  width: 100%;
}
@media only screen and (max-width: 480px) {
  .highlight-projects__screen-shot {
    height: clamp(150px, 30vh, 200px);
  }
}

.highlight-projects__screen-shot > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.highlight-projects__links {
  display: flex;
  flex-wrap: wrap;
}

.highlight-projects__links > div {
  align-items: center;
  display: flex;
  margin-right: 1.33rem;
  margin-top: 1rem;
}

.highlight-projects__links > div > img {
  height: 2rem;
  margin-right: 0.66rem;
}

.about .main-header__wrapper,
.about .main-nav-small__links {
  background-image: linear-gradient(to bottom, hsla(202deg, 60%, 27%, 0.9), hsla(213deg, 100%, 12%, 0.9)), url("../../img/backgrounds/about_bg.jpg");
}

.about-me__my-name-is {
  padding: 2.66rem 0;
}

.about-me__photo-and-array {
  display: flex;
  justify-content: flex-start;
}
@media only screen and (max-width: 480px) {
  .about-me__photo-and-array {
    flex-wrap: wrap;
  }
}

.about-me__photo--wrapper {
  width: auto;
  height: auto;
  margin-right: 2.66rem;
}
@media only screen and (max-width: 480px) {
  .about-me__photo--wrapper {
    margin-right: auto;
    margin-bottom: 2.66rem;
  }
}

.about-me__photo {
  border: 4px solid hsla(0deg, 0%, 100%, 0.5);
  box-shadow: 0.66rem 0.66rem hsl(339deg, 100%, 75%), 1.32rem 1.32rem hsl(68deg, 100%, 75%), 1.98rem 1.98rem hsl(201deg, 100%, 75%);
  margin-right: 2rem;
  margin-bottom: 2rem;
}
@media only screen and (max-width: 768px) {
  .about-me__photo {
    box-shadow: 0.5rem 0.5rem hsl(339deg, 100%, 75%), 1rem 1rem hsl(68deg, 100%, 75%), 1.5rem 1.5rem hsl(201deg, 100%, 75%);
    margin-right: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

.about-me__photo > img {
  width: clamp(300px, 90vw, 500px);
}

.about-me__skills-array {
  max-width: 40vw;
}

.about-me__skills-array > p {
  margin-top: auto;
  font-size: clamp(12px, 2vw, 20px);
}
@media only screen and (max-width: 480px) {
  .about-me__skills-array > p {
    font-size: clamp(16px, 2vw, 24px);
  }
}

.about-me__description {
  margin-top: 2.66rem;
  border: 4px solid hsla(0deg, 0%, 100%, 0.5);
  margin-bottom: 1.33rem;
  padding: 2rem 5.33rem 2rem 4rem;
  transition: background-color 400ms;
  text-align: justify;
}
@media only screen and (max-width: 768px) {
  .about-me__description {
    padding: 2rem 3rem;
  }
}
@media only screen and (max-width: 480px) {
  .about-me__description {
    padding: 3rem 2rem;
  }
}

.about-me__description span {
  font-weight: 700;
}

.about-me__description span:nth-of-type(3n) {
  color: hsl(201deg, 100%, 75%);
}

.about-me__description span:nth-of-type(3n + 1) {
  color: hsl(339deg, 100%, 75%);
}

.about-me__description span:nth-of-type(3n - 1) {
  color: hsl(68deg, 100%, 75%);
}

.about-me__technologies {
  background-color: hsl(202deg, 60%, 27%);
  padding: 2.66rem 0;
}

.technologies {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 1fr;
  gap: 1.33rem;
  margin-bottom: 1.33rem;
}
@media only screen and (max-width: 480px) {
  .technologies {
    grid-template-columns: repeat(2, 1fr);
  }
}

.technology {
  aspect-ratio: 1;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  user-select: none;
}

.technology:nth-of-type(3n) {
  border: 4px solid hsla(201deg, 100%, 75%, 0.5);
}

.technology:nth-of-type(3n + 1) {
  border: 4px solid hsla(339deg, 100%, 75%, 0.5);
}

.technology:nth-of-type(3n - 1) {
  border: 4px solid hsla(68deg, 100%, 75%, 0.5);
}

.technology__logo {
  padding: 2rem;
  height: 100%;
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .technology__logo {
    padding: 1rem;
  }
}
@media only screen and (max-width: 480px) {
  .technology__logo {
    padding: 2rem;
  }
}

.technology__logo > img {
  height: 100%;
  width: 100%;
}

.technology__name {
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: hsl(213deg, 100%, 12%);
  padding: 2rem;
  position: absolute;
  top: 100%;
  z-index: 99;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: top 400ms;
}
@media only screen and (max-width: 768px) {
  .technology__name {
    padding: 1rem;
  }
}
@media only screen and (max-width: 480px) {
  .technology__name {
    padding: 2rem;
  }
}

.technology__name {
  font-size: 1.33rem;
}
@media only screen and (max-width: 768px) {
  .technology__name {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 480px) {
  .technology__name {
    font-size: 1.33rem;
  }
}

.technology:nth-of-type(3n) > .technology__name > p {
  color: hsl(201deg, 100%, 75%);
}

.technology:nth-of-type(3n + 1) > .technology__name > p {
  color: hsl(339deg, 100%, 75%);
}

.technology:nth-of-type(3n - 1) > .technology__name > p {
  color: hsl(68deg, 100%, 75%);
}

.technology:hover .technology__name {
  top: 0;
  transition: top 300ms;
}

.projects .main-header__wrapper,
.projects .main-nav-small__links {
  background-image: linear-gradient(to bottom, hsla(202deg, 60%, 27%, 0.9), hsla(213deg, 100%, 12%, 0.9)), url("../../img/backgrounds/projects_bg.jpg");
}

.projects__sort-projects {
  border: 4px solid hsla(0deg, 0%, 100%, 0.5);
  background-color: hsl(202deg, 60%, 27%);
  margin-bottom: 2.66rem;
  padding: 1rem 2rem;
  display: flex;
  justify-content: flex-start;
  width: auto;
}

.projects__sort-projects > h3 {
  text-transform: uppercase;
}

.sort-projects__button {
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  font-size: 1.33rem;
  user-select: none;
}

.sort-projects__button > p {
  transition: opacity 150ms;
}

.sort-projects__button > img {
  height: 0.66rem;
  width: 0.66rem;
  display: flex;
  justify-content: flex-start;
  margin-left: 0.25rem;
  margin-top: 0.25rem;
  transition: transform 150ms;
}

.sort-projects__button--name {
  margin: 0 1rem;
}

.sort-projects__button--inactive > p {
  color: hsla(0deg, 0%, 100%, 0.5);
  transition: color 150ms;
}

.sort-projects__button--inactive > img {
  opacity: 0;
  transition: transform 150ms;
}

.rotate-icon {
  transform: rotate(180deg);
}

.rotate-icon--date {
  transform: rotate(0);
}

.projects__list-of-projects {
  padding: 2.66rem 0;
}

.project__content,
.project__content--second-half {
  border: 4px solid hsla(0deg, 0%, 100%, 0.5);
  margin-bottom: 1.33rem;
  transition: background-color 400ms;
  overflow: hidden;
}

.project__content:hover {
  background-color: hsl(202deg, 60%, 27%);
  transition: background-color 150ms;
}

.project__content--second-half:hover {
  background-color: hsl(213deg, 100%, 12%);
  transition: background-color 150ms;
}

.project__content > hr,
.project__content--second-half > hr {
  margin: 0 2rem;
}

.project__top {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  transition: padding 400ms;
}

.project-header-tags-wrapper {
  margin-right: 2rem;
}

.project-header-tags-wrapper > h3 {
  margin-bottom: 0.66rem;
}

.project-header-tags-wrapper > p {
  font-size: 0.75rem;
}

.project-tag-wrapper:nth-of-type(3n + 1) > .project-tag {
  color: hsl(201deg, 100%, 75%);
}

.project-tag-wrapper:nth-of-type(3n) > .project-tag {
  color: hsl(339deg, 100%, 75%);
}

.project-tag-wrapper:nth-of-type(3n - 1) > .project-tag {
  color: hsl(68deg, 100%, 75%);
}

.project-buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  position: relative;
  width: 2rem;
}

.project-buttons-wrapper > button {
  background-color: transparent;
  border: none;
}

.project-buttons-wrapper > button > img {
  width: 1rem;
}

.project__top__open-button {
  cursor: pointer;
  opacity: 1;
  transform: rotate(180deg);
  transition: opacity 100ms;
  user-select: none;
}

.project__top__close-button {
  cursor: pointer;
  opacity: 1;
  position: absolute;
  transition: opacity 100ms;
  user-select: none;
}

.project-button-hidden {
  opacity: 0;
}

.project-button-visible {
  opacity: 1;
}

.project__bottom {
  padding: 2rem;
  transition: padding 400ms;
}

.project__screen-shot {
  border: 4px solid hsla(0deg, 0%, 100%, 0.5);
  border-radius: 0.21rem;
  height: clamp(200px, 40vh, 450px);
  width: 100%;
}
@media only screen and (max-width: 480px) {
  .project__screen-shot {
    height: clamp(150px, 30vh, 200px);
  }
}

.project__screen-shot > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 50% 0;
}

.project--open {
  padding: 2rem 4rem;
  transition: padding 400ms;
}
@media only screen and (max-width: 768px) {
  .project--open {
    padding: 2rem 3rem;
  }
}
@media only screen and (max-width: 480px) {
  .project--open {
    padding: 3rem 2rem;
  }
}

.project--open--top {
  padding: 2rem 2rem 2rem 4rem;
  transition: padding 400ms;
}
@media only screen and (max-width: 768px) {
  .project--open--top {
    padding: 2rem 2rem 2rem 3rem;
  }
}
@media only screen and (max-width: 480px) {
  .project--open--top {
    padding: 2rem;
  }
}

