// FOOTER.

.footer {
  padding: 2.66rem 0;
}

.footer__links {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer__links > a {
  margin: {
    bottom: 1.33rem;
    right: 1.33rem;
  }

  text-decoration: none;
}

.footer__links__external-links {
  display: flex;
}

.footer__links__external-links > div {
  align-items: center;
  display: flex;
  
  margin: {
    bottom: 1.33rem;
    right: 1.33rem;
  }
}

.footer__links__external-links > div > img {
  height: 2rem;
  margin-right: 0.66rem;
}
