@use '../abstract/variables';
@use '../abstract/colors';


// FONTS.

@font-face {
  font-family: "Lato";
  src: local('Lato') local('Lato Regular') url('../../fonts/Lato-Regular.ttf') format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: local('Lato') local('Lato Bold') url('../../fonts/Lato-Bold.ttf') format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: local('Lato') local('Lato Black') url('../../fonts/Lato-Black.ttf') format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Roboto Mono";
  src: local('Roboto Mono') local('Roboto Mono Regular') url('../../fonts/RobotoMono-Regular.ttf') format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Roboto Mono";
  src: local('Roboto Mono') local('Roboto Mono Bold') url('../../fonts/RobotoMono-Bold.ttf') format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Inconsolata";
  src: local('Inconsolata') local('Inconsolata Regular') url('../../fonts/Inconsolata-Regular.ttf') format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Inconsolata";
  src: local('Inconsolata') local('Inconsolata Bold') url('../../fonts/Inconsolata-Bold.ttf') format("truetype");
  font-weight: 700;
  font-style: normal;
}


// DEFAULT FONT STYLES.

* {
  font-family: variables.$main-sans-serif;
  color: colors.$main-white-color;
}

html {
  font-size: clamp(16px, 2vw, 24px);
}


// TEXT ELEMENTS.

h1 {
  font-size: 4rem;
  line-height: normal;
  text-transform: uppercase;

  @media only screen and (max-width: 480px) {
    font-size: 3rem;
  }
}

h2 {
  font-size: 2rem;
  margin-bottom: 2.66rem;
  text-transform: uppercase;
}

h3 {
  font-size: 1.33rem;
}

small {
  font-size: 0.66rem;
}

.code-string > * {
  font-family: variables.$main-monospace;
  font-weight: 700;
}

.mono-string {
  font-family: variables.$main-monospace;
}


// CLASSES FOR COLOR TEXT.

.blue-text {
  color: colors.$blue-text;
}

.pink-text {
  color: colors.$pink-text;
}

.yellow-text {
  color: colors.$yellow-text;
}

.white-text {
  color: colors.$white-text;
}

.purple-text {
  color: colors.$purple-text;
}

.orange-text {
  color: colors.$orange-text;
}

.green-text {
  color: colors.$green-text;
}
