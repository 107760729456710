@use '../abstract/colors';

.default-container-width {
  margin: 0 auto;
  width: clamp(300px, 90vw, 1150px);
}

.bg-wrapper--dark {
  background-color: colors.$main-theme-color-dark;
  width: 100%;
}

.bg-wrapper--light {
  background-color: colors.$main-theme-color-light;
  width: 100%;
}

.bg-wrapper--gradient {
  background-image: linear-gradient(to bottom, colors.$main-theme-color-dark-transparent, colors.$main-theme-color-light-transparent);
  width: 100%;
}
