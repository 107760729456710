@use '../abstract/colors';
@use '../abstract/variables';


.projects .main-header__wrapper,
.projects .main-nav-small__links {
  background-image: linear-gradient(to bottom, colors.$main-theme-color-light-transparent, colors.$main-theme-color-dark-transparent), url('../../img/backgrounds/projects_bg.jpg');
}


// SORT BY

.projects__sort-projects {
  border: variables.$default-border;
  background-color: colors.$main-theme-color-light;

  margin-bottom: 2.66rem;
  padding: 1rem 2rem;

  display: flex;
  justify-content: flex-start;
  width: auto;
}

.projects__sort-projects > h3 {
  text-transform: uppercase;
}

.sort-projects__button {
  cursor: pointer;

  display: flex;
  justify-content: flex-start;
  font-size: 1.33rem;

  user-select: none;
}

.sort-projects__button > p {
  transition: opacity 150ms;
}

.sort-projects__button > img {
  height: 0.66rem;
  width: 0.66rem;

  display: flex;
  justify-content: flex-start;

  margin-left: 0.25rem;
  margin-top: 0.25rem;

  transition: transform 150ms;
}

.sort-projects__button--name {
  margin: 0 1rem;
}

.sort-projects__button--inactive > p {
  color: colors.$main-white-transparent;
  transition: color 150ms;
}

.sort-projects__button--inactive > img {
  opacity: 0;
  transition: transform 150ms;
}

.rotate-icon {
  transform: rotate(180deg);
}

.rotate-icon--date {
  transform: rotate(0);
}


// LIST OF PROJECTS

.projects__list-of-projects {
  padding: 2.66rem 0;
}

.project__content,
.project__content--second-half {
  border: variables.$default-border;

  margin-bottom: 1.33rem;
  
  transition: background-color 400ms;
  overflow: hidden;
}

.project__content:hover {
  background-color: colors.$main-theme-color-light;
  transition: background-color 150ms;
}

.project__content--second-half:hover {
  background-color: colors.$main-theme-color-dark;
  transition: background-color 150ms;
}

.project__content > hr,
.project__content--second-half > hr {
  margin: 0 2rem;
}

// TOP

.project__top {
  align-items: center;
  display: flex;
  justify-content: space-between;

  padding: 2rem;

  transition: padding 400ms;
}

.project-header-tags-wrapper {
  margin-right: 2rem;
}

.project-header-tags-wrapper > h3 {
  margin-bottom: 0.66rem;
}

.project-header-tags-wrapper > p {
  font-size: 0.75rem;
}

// Tags
.project-tag-wrapper:nth-of-type(3n + 1) > .project-tag {
  color: colors.$main-blue-color;
}

.project-tag-wrapper:nth-of-type(3n) > .project-tag {
  color: colors.$main-pink-color;
}

.project-tag-wrapper:nth-of-type(3n - 1) > .project-tag {
  color: colors.$main-yellow-color;
}

// Buttons
.project-buttons-wrapper {
  display: flex;
  justify-content: flex-end;

  position: relative;

  width: 2rem;
}

.project-buttons-wrapper > button {
  background-color: transparent;
  border: none;
}

.project-buttons-wrapper > button > img {
  width: 1rem;
}

.project__top__open-button {
  cursor: pointer;
  opacity: 1;
  transform: rotate(180deg);
  transition: opacity 100ms;
  user-select: none;
}

.project__top__close-button {
  cursor: pointer;
  opacity: 1;
  position: absolute;
  transition: opacity 100ms;
  user-select: none;
}

.project-button-hidden {
  opacity: 0;
}

.project-button-visible {
  opacity: 1;
}


// BOTTOM

.project__bottom {
  padding: 2rem;
  transition: padding 400ms;
}

// Screen shot
.project__screen-shot {
  border: variables.$default-border;
  border-radius: 0.21rem;

  height: clamp(200px, 40vh, 450px);
  width: 100%;

  @media only screen and (max-width: 480px) {
    height: clamp(150px, 30vh, 200px);
  }
}

.project__screen-shot > img {
  height: 100%;
  width: 100%;
  
  object-fit: cover;
  object-position: 50% 0;
}


// HELPERS

.project--open {
  padding: 2rem 4rem;
  transition: padding 400ms;

  @media only screen and (max-width: 768px) {
    padding: 2rem 3rem;
  }

  @media only screen and (max-width: 480px) {
    padding: 3rem 2rem;
  }
}

.project--open--top {
  padding: 2rem 2rem 2rem 4rem;
  transition: padding 400ms;

  @media only screen and (max-width: 768px) {
    padding: 2rem 2rem 2rem 3rem;
  }

  @media only screen and (max-width: 480px) {
    padding: 2rem;
  }
}
