@use '../abstract/colors';
@use '../abstract/variables';
@use '../utilities/container';


// BACKGROUND

.home .main-header__wrapper,
.home .main-nav-small__links {
  background-image: linear-gradient(to bottom, colors.$main-theme-color-light-transparent, colors.$main-theme-color-dark-transparent), url('../../img/backgrounds/home_bg.jpg');
}


// MY EXPERIENCE

.home__my-experience {
  padding: 2.66rem 0;
}

.my-experience__content {
  border: variables.$default-border;

  margin-bottom: 1.33rem;
  padding: 2rem 5.33rem 2rem 4rem;

  transition: background-color 400ms;

  @media only screen and (max-width: 768px) {
    padding: 2rem 3rem;
  }

  @media only screen and (max-width: 480px) {
    padding: 3rem 2rem;
  }
}

.my-experience__content:hover {
  background-color: colors.$main-theme-color-light;
  transition: background-color 150ms;
}

.my-experience__top {
  display: grid;
  grid-template-columns: 3rem auto;
  grid-template-rows: 3rem auto;
  gap: 0.66rem;
  
  margin-bottom: 2rem;
}

.my-experience__top > img {
  width: 100%;
}

.my-experience__top > h3 {
  align-self: center;
}

.my-experience__top__underline--pink {
  text-decoration: underline;
  text-decoration-color: colors.$main-pink-color;
  text-decoration-thickness: 6px;
}

.my-experience__top__underline--yellow {
  text-decoration: underline;
  text-decoration-color: colors.$main-yellow-color;
  text-decoration-thickness: 6px;
}

.my-experience__top__underline--blue {
  text-decoration: underline;
  text-decoration-color: colors.$main-blue-color;
  text-decoration-thickness: 6px;
}

.my-experience__bottom__tag {
  color: colors.$main-white-transparent;
}

.my-experience__bottom__description {
  border-left: 1px solid colors.$main-white-transparent;

  margin: 0.33rem 0 0.33rem 0.75rem;
  padding: 0.66rem 0 0.66rem 1rem;
}


// HIGHLIGH PROJECTS

.home__highlight-projects {
  background-color: colors.$main-theme-color-light;
  padding: 2.66rem 0;
}

.highlight-projects__content {
  border: variables.$default-border;
  box-shadow: none;

  margin-bottom: 2.66rem;
  padding: 2rem 4rem;

  transition: all 400ms;

  @media only screen and (max-width: 768px) {
    padding: 2rem 3rem;
  }

  @media only screen and (max-width: 480px) {
    padding: 3rem 2rem;
  }
}

.highlight-projects__box-shadow:nth-of-type(3n) {
  box-shadow: variables.$box-shadow-blue;
  transition: all 400ms;

    @media only screen and (max-width: 768px) {
      box-shadow: variables.$box-shadow-blue--sm;
    }
  }

.highlight-projects__box-shadow:nth-of-type(3n + 1) {
  box-shadow: variables.$box-shadow-pink;
  transition: all 400ms;

  @media only screen and (max-width: 768px) {
    box-shadow: variables.$box-shadow-pink--sm;
  }
}

.highlight-projects__box-shadow:nth-of-type(3n - 1) {
  box-shadow: variables.$box-shadow-yellow;
  transition: all 400ms;

  @media only screen and (max-width: 768px) {
    box-shadow: variables.$box-shadow-yellow--sm;
  }
}

.highlight-projects__content:last-of-type {
  margin-bottom: 2rem;
}

.highlight-projects__content:hover {
  background-color: colors.$main-theme-color-dark;
  transition: all 150ms;
}

.highlight-projects__content > h3 {
  margin-bottom: 1.33rem;
}

.highlight-projects__content > h3 > a {
  font-size: 1.33rem;
}

.highlight-projects__content > p {
  margin-bottom: 1rem;
}

.highlight-projects__screen-shot {
  border: variables.$default-border;
  border-radius: 0.21rem;

  height: clamp(200px, 40vh, 450px);
  width: 100%;

  @media only screen and (max-width: 480px) {
    height: clamp(150px, 30vh, 200px);
  }
}

.highlight-projects__screen-shot > img {
  height: 100%;
  width: 100%;

  object-fit: cover;
}

.highlight-projects__links {
  display: flex;
  flex-wrap: wrap;
}

.highlight-projects__links > div {
  align-items: center;
  display: flex;
  margin: {
    right: 1.33rem;
    top: 1rem;
  }
}

.highlight-projects__links > div > img {
  height: 2rem;
  margin-right: 0.66rem;
}
