@use '../abstract/colors';
 

.main-nav {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;

  padding: 1.33rem 0;
}

.main-nav a,
.main-nav-small__bar a {
 text-decoration: none;
}

.main-nav__list {
  display: flex;
}

.main-nav__list li {
  margin-left: 1.33rem;
}

.main-nav__list a {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main-nav__list p {
  text-transform: uppercase;
}


// ACTIVE AND INACTIVE LINKS.

// Active.
.active-link--home > div,
.active-link--projects > div,
.active-link--about > div,
.in-active-link--home > div,
.in-active-link--projects > div,
.in-active-link--about > div {
  height: 1.33rem;
  width: 1.33rem;

  background-repeat: no-repeat;
  background-size: contain;
}

.active-link--home > div {
  background-image: url('../../img/icons/navigation/home_icon_active.svg');
}

.active-link--projects > div {
  background-image: url('../../img/icons/navigation/projects_icon_active.svg');
}

.active-link--about > div {
  background-image: url('../../img/icons/navigation/about_icon_active.svg');
}

.in-active-link--home > div {
  background-image: url('../../img/icons/navigation/home_icon.svg');
}

.in-active-link--projects > div {
  background-image: url('../../img/icons/navigation/projects_icon.svg');
}

.in-active-link--about > div {
  background-image: url('../../img/icons/navigation/about_icon.svg');
}

.active-link--home > p {
  color: colors.$blue-text;
}

.active-link--projects > p {
  color: colors.$pink-text;
}

.active-link--about > p {
  color: colors.$yellow-text;
}

// Inactive.
.in-active-link--home > p,
.in-active-link--projects > p,
.in-active-link--about > p {
  color: colors.$white-text;
  transition: color 150ms;
}

.in-active-link--home:hover > p {
  color: colors.$blue-text;
}

.in-active-link--projects:hover > p {
  color: colors.$pink-text;
}

.in-active-link--about:hover > p {
  color: colors.$yellow-text;
}


// NAVIGATION SMALL DEVICES

// Navigation Bar
.main-nav-small__bar {
  align-items: center;
  display: flex;
  justify-content: flex-start;

  padding: 1.33rem 0;

  transition: all 400ms;
}

.main-nav-small__bar > a {
  font-size: clamp(18px, 4vw, 24px);
}

.main-nav-small__bar__button {
  background-color: transparent;

  border: {
    color: colors.$main-white-transparent;
    radius: 50%;
    style: solid;
    width: 1px;
  }

  box-shadow: 0 0 3px 1px colors.$main-white-color;

  margin-right: 1.33rem;

  overflow: hidden;

  height: 50px;
  width: 50px;
}

.main-nav-small__bar__button:hover {
  background-color: colors.$main-theme-color-light;
}

.main-nav-small__bar__button__icons-wrapper {
  align-items: center;
  display: flex;

  transition: translate 400ms;
}

.main-nav-small__bar__button__icons-wrapper > img {
  height: 20px;
  width: 20px;

  margin: 15px;
}

.navigation-opened {
  translate: -50px;
}

.navigation-opened--bar-bg {
  background-color: colors.$main-theme-color-light;
  box-shadow: 0 -25px 0 25px colors.$main-theme-color-light-transparent;
}

// Navigation Links
.main-nav-small__links {
  background-color: colors.$main-theme-color-dark;

  position: absolute;
  bottom: 0;

  display: flex;
  justify-content: center;

  padding: 4rem 1.33rem;
  width: 100%;

  translate: 100%;
  transition: translate 400ms;

  z-index: 99;
}

.main-nav-small__links__list {
  display: flex;
  flex-direction: column;
}

.main-nav-small__links__list > li {
  margin-bottom: 2rem;
}

.main-nav-small__links__list > li > a {
  align-items: center;
  display: flex;

  font-size: clamp(24px, 6vw, 32px);
  font-weight: bold;

  text-decoration: none;
  text-transform: uppercase;
}

.main-nav-small__links__list > li > a > div {
  height: 42px;
  width: 42px;
  
  margin-right: 2rem;
}

.navigation-opened--links {
  translate: 0;
}
